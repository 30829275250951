/*
 *
 *
 Layout
 * 
 * 
 */

* {
    box-sizing: border-box;
}

body {
    height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
    width: 100%;
}

.container {
    @extend .container-fluid;
    max-width: 1440px;
    margin: 0px auto;
    padding: 0 15px;
}

.row {
    display: block;
    width: 100%;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

$columns: 12;

$extra-small-devices: 467px; 
$small-devices: 767px; 
$medium-devices: 1120px;
$large-devices: 1439px;

/* columns */

@for $i from 1 through $columns {
    .col-#{$i} { 
        width: 100% / 12 * $i; 
        min-height: 1px;
        padding: 0px 15px;
        float: left;
    }
}

@media only screen and (max-width: $large-devices) {
     
}

@media only screen and (max-width: $medium-devices) {

        @for $i from 1 through 2 {
            .col-#{$i} { 
                width: 100%; 
            }
        }       
        @for $i from 3 through 3 {
            .col-#{$i} { 
                width: 33.333%; 
            }
        } 
        @for $i from 4 through 6 {
            .col-#{$i} { 
                width: 50%; 
            }
        }  
}

@media only screen and (max-width: $small-devices) {

        @for $i from 1 through 2 {
            .col-#{$i} { 
                width: 100%; 
            }
        }     
        @for $i from 3 through 6 {
            .col-#{$i} { 
                width: 50%; 
            }
        }    
}

@media only screen and (max-width: $extra-small-devices) {

        @for $i from 1 through $columns {
            .col-#{$i} { 
                width: 100%; 
            }
        }        
}

/* offset */

@for $i from 1 through $columns {
    .col-offset-#{$i} { 
        margin-left: 100% / $columns * $i;
    }
}

@media only screen and (max-width: $large-devices) {

    @for $i from 1 through $columns {
        .col-offset-#{$i} { 
            margin-left: 0px;
        }
    }    
}

@media only screen and (max-width: $medium-devices) {

    @for $i from 1 through $columns {
        .col-offset-#{$i} { 
            margin-left: 0px;
        }
    }         
}

@media only screen and (max-width: $small-devices) {

    @for $i from 1 through $columns {
        .col-offset-#{$i} { 
            margin-left: 0px;
        }
    }         
}

/*
 *
 *
 Helper classes
 *
 * 
 */

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}