@import 'layout';

$primary-color: #ff4f51;

body {
    margin: 0;
    padding: 0 0 40px 0;
    font-size: 16px;
    font-family: "proxima-nova",sans-serif;
}

a {
    text-decoration: none;
    color: #444;

    &:hover {
        text-decoration: none;
        color: $primary-color;
    }
}

img {
    max-width: 100%;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .33);
    z-index: 10;
    display: none;
}

#header {
    width: 100%;
    height: 720px;
    background: #f6faff;
    background: url('assets/header-background.jpg');
    background-repeat: repeat-x;

    .logo {
        position: absolute;
        top: 30px;
        left: 30px;

        img {
            width: 100%;
            max-width: 150px;
        }
    }

    #header-bg {
        background: url('assets/header-image.jpg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position: calc(50% - 35%) center;   
    }

    .container {
        width: 100%;
        height: 720px;
        position: relative;
    }

    #header-menu {
        position: relative;
        z-index: 1;
        ul {
            list-style: none;
            float: right;
            margin: 40px 0 0 0;
            padding: 0 15px;

            li {
                display: inline-block;
                margin-left: 40px;

                a {
                    font-size: 18px;
                    font-weight: 700;
                    color: #444;
                    text-decoration: none;

                    &:hover {
                        color: $primary-color;
                    }
                }

                span {
                    position: relative;
                    top: -1px;
                    display: inline-block;
                    border-radius: 4px;
                    background: $primary-color;
                    color: #fff;
                    margin: 0 0 0 5px;
                    padding: 7px 10px 6px 10px;
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
    }

    #header-title {
        width: 45%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 40px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        align-items: left;

        h1 {
            font-family: "filson-soft",sans-serif;
            font-size: 56px;
            font-weight: 700;
            color: $primary-color;
            margin-bottom: 20px;
        }
    }   

    #header-subtitle {
        margin-bottom: 10px;
        color: #444;
        font-size: 24px;
        font-weight: 700;
    } 
}

#contentHeader {

    #header-menu {
        position: fixed;
        top: -71px;
        width: 100%;
        height: 71px;
        transition: top 0.3s ease-in-out;
        text-align: right;
        background: #fff;
        border-bottom: 2px solid #e9ecf0;
        z-index: 5;

        .container {
            position: relative;
        }

        .logo {
            position: absolute;
            top: 20px;
            left: 30px;

            img {
                width: 100%;
                max-width: 150px;
            } 
        }

        ul {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 20px 15px;

            li {
                display: inline-block;
                margin-left: 40px;

                a {
                    font-size: 18px;
                    font-weight: 700;
                    color: #444;
                    text-decoration: none;

                    &:hover {
                        color: $primary-color;
                    }
                }

                span {
                    position: relative;
                    top: -1px;
                    display: inline-block;
                    border-radius: 4px;
                    background: $primary-color;
                    color: #fff;
                    margin: 0 0 0 5px;
                    padding: 7px 10px 6px 10px;
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }

        &.nav-down {
            top: 0px;
        }

        &.nav-up {
            top: -71px;
        }
    }

    #header-title {
        padding: 111px 30px 40px 30px;
        text-align: center;
        background: #f6f9fd;

        h1 {
            font-family: "filson-soft",sans-serif;
            font-size: 32px;
            font-weight: 700;
            color: $primary-color;
            margin: 0;
            padding: 0;
        }
    }
}

.breadcrumb {
    padding: 20px 15px 40px 15px;
    text-align: right;
    font-size: 14px;

    height: 17px;
    position: relative;
    overflow: hidden;
    box-sizing: content-box;

    &:after {
        content: '';
        position: absolute;
        top: 20px;
        left: 0;
        width: 80px;
        height: 17px;
        background: linear-gradient(to right, white , rgba(255, 255, 255, 0));
    }

    .breadcrumb-arrow {
        margin: 0 8px;
    }

    #kontextMenu {
        width: 960px;
        height: 17px;
        overflow: hidden;
        box-sizing: content-box;
        position: absolute;
        top: 20px;
        right: 15px;
    }

    a:last-child {
        font-weight: 700;
    }
}

#content {

    #services {
        margin: 40px 0 40px 0;

        .icon-container {
            position: relative;
            display: inline-block;
            height: auto;
            margin-bottom: 10px;

            .icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 175px;
                background-repeat: no-repeat;
                background-position: top center;
                background-size: 100%;
                transition: top .3s ease;
            }

            .icon-01 {
                .layer-01 {
                    background-image: url('assets/ppc_01.svg');
                }
                .layer-02 {
                    background-image: url('assets/ppc_02.svg');
                }

                &:hover {
                    .layer-02 {
                        top: -10px;
                    }
                }
            }

            .icon-02 {
                .layer-01 {
                    background-image: url('assets/sprava_socialnych_sieti_01.svg');
                }
                .layer-02 {
                    background-image: url('assets/sprava_socialnych_sieti_02.svg');
                }

                &:hover {
                    .layer-02 {
                        top: -10px;
                    }
                }
            }

            .icon-03 {
                .layer-01 {
                    background-image: url('assets/email_marketing_01.svg');
                }
                .layer-02 {
                    background-image: url('assets/email_marketing_02.svg');
                }

                &:hover {
                    .layer-02 {
                        top: -10px;
                    }
                }
            }

            .icon-04 {
                .layer-01 {
                    background-image: url('assets/inzercia_na_vlastnej_stranke_01.svg');
                }
                .layer-02 {
                    background-image: url('assets/inzercia_na_vlastnej_stranke_02.svg');
                }

                &:hover {
                    .layer-02 {
                        top: -10px;
                    }
                }
            }

            img {
                max-width: 100%;
            }
        }

        .services-container{
            padding: 40px 0;
            text-align: center;

            h2 {
                font-family: "filson-soft",sans-serif;
                font-size: 20px;
                font-weight: 400;
            }
        }
    }

    .article-large, .article-small {
        position: relative;
        width: 100%;
        height: auto;
        border: 2px solid #e9ecf0;
        border-radius: 4px;
        font-family: "filson-soft",sans-serif;
        color: #444;

        &:hover {
            background: #f6f9fd;
            color: $primary-color;

        }
    }

    .article-large {
        height: auto;
        padding-bottom: 40px;
        font-size: 28px;

        .article-image-container {
            overflow: hidden;
            width: 100%;
            height: 270px;
            margin: -2px 0 0 -2px;
            padding: 4px 0 0 4px;
            box-sizing: content-box;
            border-radius: 4px 4px 0 0;

            .article-image {
                margin: -4px 0 0 -4px;
                padding: 8px 0 0 8px;
                box-sizing: content-box;
                width: 100%;
                height: 270px;
                background: #000;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                border-radius: 4px 4px 0 0;
                transition: transform .33s ease;
            }
        }

        .article-title {
            padding: 30px 40px 0 40px;
        }       

        &:hover .article-image {
            transform: scale(1.1);
        }
    }

    .article-small {
        margin: 0 0 20px 0;
        padding: 30px 92px 30px 40px;
        font-size: 20px;
        background: url('assets/icon-more.svg');
        background-repeat: no-repeat;
        background-size: 32px 32px;
        background-position: right 40px top 27px;

        p {
            margin-bottom: 0;
            font-family: "proxima-nova",sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
        }

        &:hover {
            background: #f6f9fd url('assets/icon-more-active.svg') no-repeat right 40px top 27px;
        }
    }
}

#subpage-content {

    *:first-child {
        margin-top: 0;
    }

    *:last-child {
        margin-bottom: 0;
    }
    
    p {
        line-height: 1.5;
    }

    .pagination {
        margin-top: 40px;
        text-align: center;

        &:empty {
            margin-top: 0;
        }
    }

    .services-container {
        border: 2px solid #e9ecf0;
        border-radius: 4px;
        padding: 40px 40px 40px 250px;
        margin-top: 15px;
        margin-bottom: 15px;
        background-repeat: no-repeat;
        background-size: 210px;
        background-position: top 30px left 20px;

        .services-title {
            font-family: "filson-soft",sans-serif;
            font-size: 20px;
            color: $primary-color;
            margin-bottom: 15px;
            display: block;
        }

        .services-desc {
            font-size: 16px;
            line-height: 1.5;

            p {
                margin: 0;
                margin-bottom: 15px;
            }
        }
    }

    .blog-container {
        border: 2px solid #e9ecf0;
        border-radius: 4px;
        padding: 40px 40px 40px 40px;
        margin-top: 15px;
        margin-bottom: 15px;

        &:hover {
            .blog-img {
                transform: scale(1.1);
            }
        }

        .blog-img-container {
            position: relative;
            width: 100%;
            height: auto;
            overflow: hidden;
            margin: -42px 0 30px -42px;
            padding: 84px 0 0 84px;
            border-radius: 4px 4px 0 0;
            box-sizing: content-box;

            .blog-img {
                margin: -84px 0 0 -84px;
                padding: 168px 0 0 168px;
                box-sizing: content-box;
                width: 100%;
                height: 100px;
                background-color: #e9ecf0;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                transition: transform .3s ease;
            }
        }

        .blog-title {
            font-family: "filson-soft",sans-serif;
            font-size: 20px;

            color: $primary-color;
            margin-bottom: 15px;
            display: block;

            a {
                text-align: left;
                color: $primary-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .services-desc {
            font-size: 16px;
            line-height: 1.5;

            p {
                margin: 0;
                margin-bottom: 15px;
            }
        }
    }

    .reference-container {
        border: 2px solid #e9ecf0;
        border-radius: 4px;
        padding: 15px 30px;
        margin: 15px 0;
        text-align: center;

        img {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            transition: filter .3s ease;
        }

        &:hover {
            img {
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
            }
        }
    }

    .blog-article {
        max-width: 760px;
        margin: 0px auto;
        margin-bottom: 80px;

        .blog-info {
            padding: 15px 30px 15px 60px;
            margin-bottom: 40px;
            border-top: 1px solid #e9ecf0;
            border-bottom: 1px solid #e9ecf0;
            position: relative;

            .author-img {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                background: #e9ecf0;
                background-image: url('assets/katka_bartova.jpg');
                background-position: center;
                background-size: cover;
                display: inline-block;
                position: absolute;
                top: 5px;
                left: 15px;
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    display: inline-block;
                    border-right: 1px solid #e9ecf0;
                    padding: 0 15px 0 10px;

                    &:last-child {
                        border: none;
                    }
                }
            }
        }

        h2 {
            text-align: center;
            font-size: 48px;
            margin: 40px 0;
        }
    }
}

#call-to-action {
    margin-top: 40px;

    .call-to-action-container {
        padding: 40px 40px 45px 40px;
        border-radius: 4px;
        background: #f6f9fd;
        text-align: center;

        p {
            margin-top: 0;
            font-family: "filson-soft",sans-serif;
            font-size: 20px;
        }
    }
}

#contact {
    margin-top: 40px;

    form {
        padding: 40px;
        background: #f6f9fd;
        border-radius: 4px;

        label {
            display: block;
            margin: 0 0 10px 0;
            font-weight: 700;
        }

        input[type="text"], input[type="email"], input[type="tel"], textarea {
            display: block;
            width: 100%;
            margin: 0 0 20px 0;
            border: 2px solid #e9ecf0;
            border-radius: 4px;
            padding: 0 20px;
        }

        input[type="text"], input[type="email"], input[type="tel"] {
            height: 54px;
        }

        textarea {
            padding: 15px 20px;
            height: 262px;
            resize: none;
        }

        .g-recaptcha {
            margin-bottom: 15px;
        }
    }

    .contact-info {
        padding: 40px 40px 0 40px;

        span {
            display: block;
            margin-bottom: 10px;
        }

        .facebook {
            display: inline-block;
            margin-top: 15px;
            margin-right: 10px;
        }
        .instagram {
            display: inline-block;
            margin-top: 15px;
        }
    }

    form.active {
        position: relative;
        z-index: 999;
    }
}

.social-icons {
    text-align: center;
    margin-top: 40px;

    .facebook {
        display: inline-block;
        margin-right: 10px;
    }
    .instagram {
        display: inline-block;
    }
}

#brands {
    margin-top: 40px;

    .owl-stage {
        margin: 0px auto;
    }

    span {
        display: inline-block;
        padding: 0 30px;
    }

    img {
        width: auto !important;
        margin: 0px auto !important;
        opacity: .5 !important;

        &:hover {
            opacity: 1 !important;
        }
    }
}

#footer {
    margin-top: 40px;
    text-align: center;

    #copy {
        border-top: 2px solid #e9ecf0;
        padding-top: 40px;
    }
}

hr {
    margin: 30px 0;
    border: none;
    border-bottom: 2px dotted #e9ecf0;
}

.btn {
    display: inline-block;
    padding: 15px 30px;
    background: $primary-color;
    border: none;
    border-radius: 4px;
    font-family: "proxima-nova",sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #fff;
    }
}

.btn-ghost {
    display: inline-block;
    padding: 15px 30px;
    background: transparent;
    border: 2px solid $primary-color;
    border-radius: 4px;
    font-family: "proxima-nova",sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
        background: $primary-color;
        color: #fff;
    }
}

#nav {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    background: rgba(255, 255, 255, .97);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    #nav-close {
        position: absolute;
        top: 20px;
        right: 30px;
        width: 32px;
        height: 32px;
        background-image: url('assets/close.svg');
        background-repeat: no-repeat;
        background-size: 32px 32px;
    }

    ul {
        width: 100%;
        list-style: none;
        padding: 0;

        li {
            font-family: "filson-soft",sans-serif;
            font-size: 20px;
            font-weight: 700;
            margin: 15px 0;
            border-bottom: 1px solid #e9ecf0;
            padding-bottom: 15px;

            &:last-child {
                border-bottom: none;
            }

            span {
                background: $primary-color;
                color: #fff;
                font-size: 14px;
                padding: 5px 15px;
                border-radius: 4px;
                margin-left: 10px;
                position: relative;
                top: -2px;
            }
        }
    }
}

#nav-icon {
    position: absolute;
    top: 19px;
    right: 15px;
    width: 32px;
    height: 32px;
    background-image: url('assets/nav.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: none;
    z-index: 1;
}

@media only screen and (max-width: $medium-devices) {

    #subpage-content { 
        .services-container {
            padding: 175px 20px 20px 20px;
            background-size: 210px;
            background-position: top 20px center;

            .services-title, .services-desc {
                text-align: center;
            }

            a {
                display: block;
                text-align: center;
                margin-top: 20px;
            }
        } 
    }

    #contentHeader #header-menu ul { display: none; }
    #contentHeader #header-menu #nav-icon { display: block; }
    #contentHeader #header-menu .logo { left: 15px; }

    #header #logo { top: 25px; }

    #header #header-menu ul { display: none; }
    #header #header-menu #nav-icon { display: block; }

    #header { height: 100vh; }
    #header #header-bg { background-image: url('assets/header-image-mobile.jpg'); background-size: cover; background-position: center; }
    #header #header-bg .container { height: 100vh; }
    #header #header-title { position: relative; top: auto; right: auto; left: auto; width: 100%; max-width: 500px; height: 100vh; margin: 0px auto; padding: 0 40px; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; }
    #header #header-title h1 { font-size: 40px; }

    #contentHeader #header-title h1 { font-size: 24px; }

    #services .col-3 { width: 50%; }

    #articles .col-4:nth-child(1) { width: 50%; margin-bottom: 20px; }
    #articles .col-4:nth-child(2) { width: 50%; margin-bottom: 20px; }
    #articles .col-4:last-child { width: 100%; }
    #articles .col-4:last-child .article-small { margin-bottom: 0; }

    #contact .col-8 { width: 100%; }
    #contact .col-4 { width: 100%; text-align: center; }

    #contact .col-4 { padding: 0 20px 0 20px; }

    #subpage-content .blog-article { padding: 0 20px; margin-bottom: 40px; }
    #subpage-content .blog-article h2 { font-size: 32px; margin-top: 0; }
    
}

@media only screen and (max-width: $small-devices) {

    #subpage-content {
        .reference-container {
            img { filter: grayscale(0%); max-height: 75px; }
        }

        .blog-container {
            padding: 20px 20px 20px 20px;

            a {
                display: block;
                text-align: center;
                margin-top: 20px;
            }

            .blog-img-container {
                margin: -22px 0 15px -22px;
                padding: 44px 0 0 44px;

                .blog-img {
                    margin: -44px 0 0 -44px;
                    padding: 88px 0 0 88px;
                }
            }
        }
    }   

    #header #header-title h1 { font-size: 32px; }

    #services .col-3 { width: 100%; }

    #articles .col-4 { width: 100% !important; }
    #articles .col-4:nth-child(2) { margin-top: 20px; }
    #articles .col-4:nth-child(3) { margin-top: 20px; }
    #articles .col-4:last-child { margin-top: 0; }
    #articles .col-4:last-child .article-small { margin-bottom: 0; }

    #contact form { padding: 20px 5px; }
    #contact form .btn { width: 100%; }
    #contact form .g-recaptcha { transform: scale(.72); transform-origin: 0 0; margin-bottom: 0; }

    #subpage-content .blog-article .blog-info ul li {
        display: block;
        border: none;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    #subpage-content .blog-article .blog-info .author-img {
        top: 10px;

    }

} 

@media only screen and (min-width: $medium-devices) {

    /* references */
    #subpage-content .col-3:first-child .reference-container { margin-top: 40px; }
    #subpage-content .col-3:nth-child(2) .reference-container { margin-top: 40px; }
    #subpage-content .col-3:nth-child(3) .reference-container { margin-top: 40px; }
    #subpage-content .col-3:nth-child(4) .reference-container { margin-top: 40px; }

}

@media only screen and (min-width: $small-devices) and (max-width: $medium-devices) {

    /* references */
    #subpage-content .col-3:first-child .reference-container { margin-top: 40px; }
    #subpage-content .col-3:nth-child(2) .reference-container { margin-top: 40px; }
    #subpage-content .col-3:nth-child(3) .reference-container { margin-top: 40px; }

}

@media only screen and (min-width: $extra-small-devices) and (max-width: $small-devices) {

    /* references */
    #subpage-content .col-3:first-child .reference-container { margin-top: 40px; }
    #subpage-content .col-3:nth-child(2) .reference-container { margin-top: 40px; }
    /* blog */
    #subpage-content .col-4:first-child .blog-container { margin-top: 0; }
    #subpage-content .col-4:nth-child(2) .blog-container { margin-top: 0; }

}

@media only screen and (max-width: $extra-small-devices) {

    #subpage-content .col-6:last-child .services-container { margin-bottom: 0; }
    #subpage-content .col-3:first-child .reference-container { margin-top: 40px; }
    #subpage-content .col-3:last-child .reference-container { margin-bottom: 0; }
    #subpage-content .col-4:first-child .blog-container { margin-top: 0; }
    #subpage-content .col-4:nth-last-child(2) .blog-container { margin-bottom: 0; }

}

